import {
    Loan,
    Loan_fee,
    Repay_Info_Stage,
    Repay_Info_Rong_Stage,
    Repay_Info_All,
    Bank_list,
    Valid_card,
    Bind_Capital_Card_sms,
    Bind_Card,
    Bind_Card_List,
    Repay_Send_Sms,
    Repay_Submit,
    Pay_Check_Settle,
    Pay_Check_Normal,
    Loan_Contract,
    Card_bank,
    Card_send_bank,
    
} from '../services/loan';
import { Client_auth_get } from "../services";

export default {
    namespace: 'NSLoan',
    state: {
        name: 'NSLoan',
        ClientInfo: {},
        LoanFee: null,
        BankListInfo: [],
        RepayList: null,
        RepayRongList:null,
        LoanInfo: null,
        LoanRongInfo: null,
        PayInfo: null,
        ValidCardStatus: null,
        isSettle:null,
        payArray:null,
        urlList:[],
        
    },
    reducers: {
        redu_client_info(state, { payload }) {
            return {
                ...state,
                ClientInfo: payload
            }
        },
        redu_loan_fee(state, { payload }) {
            return {
                ...state,
                LoanFee: { ...payload }
            }
        },
        redu_bank_list(state, { payload }) {
            return {
                ...state,
                BankListInfo: payload
            }
        },
        redu_bank_qinjia_list(state, { payload }) {
            return {
                ...state,
                BankListInfo: payload
            }
        },
        redu_valid_card(state, { payload }) {
            return {
                ...state,
                ValidCardStatus: payload
            }
        },
        redu_stage_info(state, { payload }) {
            return {
                ...state,
                RepayList: payload.planRemindList,
                LoanInfo: payload,
                urlList:payload.urlList,
               
            }
        },
        // rong
        redu_rong_stage_info(state, { payload }) {
            return {
                ...state,
                RepayRongList: payload.planRemindList,
                LoanRongInfo: payload
            }
        },
        // repay status hy
        redu_status_stage_info(state, { payload }) {
            return {
                ...state,
                isSettle: payload.isSettle,
                payArray: payload,
               
            }
        },
        redu_repay_info(state, { payload }) {
            return {
                ...state,
                PayInfo: payload
            }
        }
    },
    effects: {
       
        // 添加银行卡hy
        * card_bank_init({ payload, callback }, { put, call }) {
            /*绑定签约银行卡*/
            const res = yield call(Card_bank, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
        }
        },
        // 添加银行卡发送短信hy
        * card_bank_sms({ payload, callback }, { put, call }) {
            /*绑定签约银行卡*/
            const res = yield call(Card_send_bank, { ...payload });
            console.log(res,23);
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },






        /* 獲取用戶授信額度*/
        * eff_client_info({ payload, callback }, { put, call, select }) {
            const token = yield select(state => state.token);
            if (token !== null) {
                const res = yield call(Client_auth_get, { ...payload });
                if (res.success) {
                    yield put({ type: 'redu_client_info', payload: res.result });
                    callback && callback(res);
                }
            }
        },

        * loan_create({ payload, callback }, { put, call }) {
            /*借款*/
            const res = yield call(Loan, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * loan_fee({ payload, callback }, { put, call }) {
            /*借款计算服务费*/
            const res = yield call(Loan_fee, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
                yield put({ type: 'redu_loan_fee', payload: res.result });
            }
        },
        * eff_bank_list({ payload, callback }, { put, call }) {
            const res = yield call(Bank_list, { ...payload });
            if (res.success) {
                yield put({ type: 'redu_bank_list', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },

        * eff_valid_card({ payload, callback }, { put, call }) {
            const res = yield call(Valid_card, { ...payload });
            if (res.success) {
                yield put({ type: 'redu_valid_card', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },

        * bind_capital_card_sms({ payload, callback }, { put, call }) {
            /*绑定亲家签约*/
            const res = yield call(Bind_Capital_Card_sms, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },

        * bind_card({ payload, callback }, { put, call }) {
            /*绑定签约银行卡*/
            const res = yield call(Bind_Card, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },

        * bind_card_list({ payload, callback }, { put, call }) {
            /*签约银行卡列表*/
            const res = yield call(Bind_Card_List, { ...payload });
            if (res.success) {
                yield put({ type: 'redu_bank_qinjia_list', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }

        },

        * repay_stage_info({ payload, callback }, { put, call }) {
            
            /*还款详情 hy*/
            const res = yield call(Repay_Info_Stage, { ...payload });
            if (res.success) {
                // console.log(res,8544);
                yield put({ type: 'redu_stage_info', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },
        // rong360
        * repay_rong_stage_info({ payload, callback }, { put, call }) {
            
            /* 账单详情 rong360*/
            const res = yield call(Repay_Info_Rong_Stage, { ...payload });
            if (res.success) {
                yield put({ type: 'redu_rong_stage_info', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },
        //repay_stage_status 当前的还款状态  hy
        * repay_stage_status({ payload, callback }, { put, call }) {
            
            const res = yield call(Repay_Info_All, { ...payload });
            if (res.success) {
                console.log(res,944);
                localStorage.setItem('cardChange',res.result.canChangeCard)
                yield put({ type: 'redu_status_stage_info', payload });
                if (callback && typeof callback === 'function') {
                    callback();
                }
            }
           
        },

        * repay_all_info({ payload, callback }, { put, call }) {
            /* 全部结清*/
            const res = yield call(Repay_Info_All, { ...payload });
            if (res.success) {
                yield put({ type: 'redu_repay_info', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }
        },
        * repay_send_sms({ payload, callback }, { put, call }) {
            /*借款详情*/
            const res = yield call(Repay_Send_Sms, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * repay_submit({ payload, callback }, { put, call }) {
            /*借款详情*/
            const res = yield call(Repay_Submit, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * check_settle({ payload, callback }, { put, call }) {
            /*还款结清*/
            const res = yield call(Pay_Check_Settle, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * check_normal({ payload, callback }, { put, call }) {
            /*还款单期*/
            const res = yield call(Pay_Check_Normal, { ...payload });
            if (res.success) {
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        * loan_contract_info({ payload, callback }, { put, call }) {
            /*借款详情*/
            const res = yield call(Loan_Contract, { ...payload });
            if (res.success) {
                callback && callback(res.result);
            }
        }
    }
};
