import React from 'react';
import {Card} from 'antd-mobile';
import './index.less';

const Panel = ({children, border, onClick, bodyCls, className}) => {

  let cls = className ? `${className} panel` : `panel`;

  if (border) {
    cls += ' panelBorder';
  }


  const clsBody = bodyCls?`${bodyCls} panelBody`:'panelBody';

  return (
    <Card
      onClick={(e) => {
        onClick && onClick(e);
      }}
      className={cls}>
      <Card.Body className={clsBody}>
        {children}
      </Card.Body>
    </Card>
  )
};
Panel.propTypes = {};

export default Panel;
