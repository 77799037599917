import React from 'react';
import { connect } from 'dva';
import './index.less';
// import Layer from '../../components/layout/layout';
import {Panel, Layout,Btn,Layer} from '../../components/index'
const navigation={
  title: "支持的银行卡",
  left: true,
}
class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      result:[]
     };
  }
  componentDidMount() {
    //银行卡列表
     //查看银行卡 
    //  const {result}=this.state; 
     const {dispatch}=this.props;
     const url='supportBank';
     dispatch({
       type:'NSBank/eff_request',
       payload:{url},
       callback:(res)=>{
           console.log(res,9990)
         if(res.success){   
          this.setState({
              result:res.result.result
          })
         }
       }
     })

  }
  render() {

        const {result}=this.state;
        //银行卡列表
        let RenderBank= result && result.map((item,index)=>{
              return(
              <div key={index} className={'banks'}> 
                    <div className={'banks-main'}>
                         <div className={'banks-img'}></div>
                        <div className={'banks-text'}>{item.bankName}</div>
                    </div>
              </div>
              )
          })

    return (
      // <Layout navigation={navigation}>
        <div className={'bankResult-page'}>
            {/* 33 */}
            {RenderBank}
        </div>
          
    //  </Layout>
    );
  }
}

export default connect(({NSBank})=>({NSBank}))(index);