import React from 'react';
import './index.less';
import {Modal} from "antd-mobile";
import Btn from "../Btn";


const Layer = ({children, className, bodyCls, btn, btnText, btnHandler, visible, titleCenter, callback, onClose,onCloseFlag=true, title}) => {

  let cls = className ? `${className} m-modal` : `m-modal`;
  const clsBody = bodyCls ? `${bodyCls} m-modal-body` : `m-modal-body`;

  if (titleCenter) {
    cls += ' m-modal-header-center'
  }
  const RenderTitle = <div className={'m-modal-title'}>{title}</div>;

  // 是否有按钮
  const RenderBtn = <Btn onClick={() => {
    if(onCloseFlag){
      onClose && onClose();
    }
    callback && callback();
  }} className={'m-modal-btn'}>{btnText ? btnText : '完成'}</Btn>;

  // 按钮的回调

  return (
    <Modal
      popup={true}
      className={cls}
      closable={true}
      transparent
      animationType="slide-up"
      maskClosable={true}
      visible={visible}
      title={RenderTitle}
      onClose={() => {
          onClose && onClose();
      }}>
      <div className={clsBody}>
        {/*内容*/}
        {children}
      </div>
      {/*底部按钮*/}
      {btn && RenderBtn}
    </Modal>
  )
};

Layer.propTypes = {};

export default Layer;
