import request from './fetch';
import {Toast} from "antd-mobile";
import api from '../config/api';
import {money, SearchParams} from './filter';
import {Domain, Env} from '../config/env';


/*APP方法调转H5，传参*/
export const H5Url = (path, params) => {
    let url = `${Domain}/index.html`;
    if (params) {
        url += '?';
        for (let [key, value] of Object.entries(params)) {
            url += `${key}=${value}&`;
        }
    }
    const lastIndex = url.lastIndexOf("&");
    if (lastIndex !== -1) {
        url = url.substring(0, lastIndex);
    }
    
    url += `#/${path}`;
    return url;
};

/*Front 用 借款还款携带 ClientNo*/
export const ChangeUrlH5 = (url, clientNo) => {
    const urlArr = url.split('#');
    // 测试代码
    // let turl = `${urlArr[0]}?clientNo=${clientNo}#${urlArr[1]}` ; 
    // window.localStorage.setItem('testurllll', turl);
    return `${urlArr[0]}?clientNo=${clientNo}#${urlArr[1]}`;
};

/*服务端接口 测试*/
let ServerConfig = {
    Front: 'http://coeus.jqtianxia.cn',
    // Front: 'http://118.31.124.88:8096',
    Portal: 'http://47.99.245.36:8099'
    // Portal: 'http://47.99.245.36:8099'
};
if (Env === "prod") {
    ServerConfig = {
        Front: 'https://coeus.jqtianxia.com',
        Portal: 'https://portal.jqtianxia.com'
    };
}
export const BaseUrl = ServerConfig;

export {
    request,
    api,
    money,
    SearchParams
}
