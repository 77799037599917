import React from 'react';
import {connect} from 'dva';
import Swiper from 'react-id-swiper';
import './index.less';
import {money, H5Url} from "../../utils";
import APP from "../../utils/APP";

class Index extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    componentDidMount() {
        const self = this;
        // 切换到前台展示的时候
        APP.PAGE_WILL_LOAD(function () {
            self.init();
        });
        
        APP.SET_REFESH();
        // 第一次进入
        this.init();
    }
    
    init() {
        const {dispatch} = this.props;
        /*借款详情*/
        dispatch({
            type: 'NSApp/eff_request',
            payload: {
                at: 'yyyq.app.index',
                v: '1.0.0'
            }
        });
    }
    
    // banner 图片调转
    swipperHandle(item) {
        const {targetUrl, targetType} = item;
        APP.JUMP(targetUrl, targetType);
    }
    
    
    render() {
        const {index} = this.props.NSApp;

        const {bannerList, top, times, cardIndex, clientNo} = index;

        const RenderCardItem = cardIndex && cardIndex.map((itemss, bindex) => {
            return (
                    <div className={'card-box'} key={bindex}>
                        <div className={'c-header'}>
                            <h4>{itemss.prodShowName}</h4>
                            {itemss.slogans && itemss.slogans.map((item, index) => (
                                <div key={index}>{item}</div>
                            ))}
                        </div>
                        <div className={'c-center'}>
                            <div className={'c-c-title'}>最高可借(元)</div>
                            <div className={'c-c-body'}>
                                <div className={"c-c-left"}>{money(itemss.availableAmt)}</div>
                                <div  onClick={() => {
                                    // 设置标题
                                    if (itemss.button.clickable === 1) {
                                        if (clientNo) {
                                            const url = itemss.button.url;
                                            if (url.indexOf('index.html') !== -1) {
                                                const urlArr = url.split('#');
                                                const newUrl = `${urlArr[0]}?clientNo=${clientNo}#${urlArr[1]}`;
                                                APP.JUMP(newUrl);
                                            } else if (url.indexOf('tab') !== -1) {
                                                const index = url.lastIndexOf("=");
                                                const TabNum = url.substring(index + 1);
                                                APP.JUMP_TAB(TabNum);
                                            } else {
                                                APP.JUMP(url);
                                            }
                                        } else {
                                            APP.JUMP(itemss.button.url);
                                        }
                                    }
                                }} className={"c-c-right"}>
                                    <div className={'c-c-btn'}>{itemss.button.label}</div>
                                </div>
                            </div>
                        </div>
                        <div className={'c-footer'}>
                            <span>{itemss.creditTip}</span>
                        </div>
                    </div>
            )
        });
  
      
        // swipper banner 参数
        const params = {
            observer: true,
            observeParents: true,
            autoplay: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            spaceBetween: 30,
            delay: times ? times * 1000 : 3000
        };
        
        // banner 项
        const RenderBannerItem = bannerList && bannerList.map((item, index) => {
            return (
                <div onClick={() => {
                    this.swipperHandle(item);
                }} key={index} className={'b-slide'}>
                    <div className={'b-slide-box'}>
                        <img src={item.imgUrl} alt={'banner'}/>
                    </div>
                </div>
            )
        });
        
        return (
            <div className={'index'}>
                
                {/*banner*/}
                <div className={'headerBar'}>
                    <div className={'h-left'}>logo</div>
                    <div onClick={() => {
                        APP.IS_LOGIN(function (flag) {
                            if (flag === 'false') {
                                APP.JUMP('winwallet://page/login')
                            } else {
                                const {linkUrl, title} = top;
                                APP.JUMP(linkUrl, title)
                            }
                        })
                    }} className={'h-right'}>意见反馈
                    </div>
                </div>
                
                {/*状态卡片*/}
                <div className={'card'} >
                    {RenderCardItem}
                </div>
                {/*推荐分期*/}
                <div className={'recommend'}>
                    <div className="p-list">
                        <div className="p-header">
                            <span className="p-header-h4">推荐分期</span>
                            <span className="p-header-span">精选平台 极速放款</span>
                        </div>
                        <div className="p-list-box">
                            <div onClick={() => {
                                const url = H5Url('recommend', {
                                    type: 1
                                });
                                APP.JUMP(url, '旅游分期');
                                
                                // APP.DIALOG_SHOW(
                                //     "我是标题",
                                //     "我是内容"
                                //     , function (resp) {
                                //         alert(resp);
                                //     });
                                
                            }} className="p-item">
                                <div className="p-item-left">
                                    <span className="p-item-left-h4">旅游分期</span>
                                    <span className="p-item-left-span">开心游玩</span>
                                </div>
                                {/* <div className="p-item-right">
                                    <div className="p-item-img-1"/>
                                </div> */}
                            </div>
                            <div onClick={() => {
                                const url = H5Url('recommend', {
                                    type: 2
                                });
                                APP.JUMP(url, '整形分期');
                            }} className="p-item">
                                <div className="p-item-left">
                                    <span className="p-item-left-h4">整形分期</span>
                                    <span className="p-item-left-span">轻松美丽</span>
                                </div>
                                {/* <div className="p-item-right">
                                    <div className="p-item-img-2"/>
                                </div> */}
                            </div>
                            <div onClick={() => {
                                const url = H5Url('recommend', {
                                    type: 3
                                });
                                APP.JUMP("https://mmz.jqtianxia.com/index.html?source=SJJQ", '数码分期');
                            }} className="p-item">
                                <div className="p-item-left">
                                    <span className="p-item-left-h4">数码分期</span>
                                    <span className="p-item-left-span">购买无忧</span>
                                </div>
                                {/* <div className="p-item-right">
                                    <div className="p-item-img-3"/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                  
                {/*广告图片*/}
                <div className={'banner'}>
                    <Swiper {...params}>
                        {RenderBannerItem}
                    </Swiper>
                </div>
                
                
                {/*底部内容*/}
                <div className={'footer'}>
                    <div className={'f-box'}/>
                    {/* <div className={'f-box-tips'}>本平台不向在校学生提供服务</div> */}
                </div>
            
            </div>
        )
            ;
    }
}

export default connect(({loading, NSApp}) => ({loading, NSApp}))(Index);
