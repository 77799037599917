import {bank_Support,bank_ValdateCardNo,bank_SendSms,bank_Finish,bank_CardBind} from '../services/bank';
import bankResult from '../routes/bankResult';
export default{
    namespace: 'NSBank',
    state: {
        name: 'NSBank',
    
        bankInfo:null,

        bankResultList:null
    },
  reducers: {
    updatebankInfo: function (state, {payload}) {
      return {
        state,
        bankInfo: payload
      }
    },
    updatebankResult: function (state, {payload}) {
      return {
        state,
        bankResultList: payload
      }
    },
   
  },
  effects: {
    * card_bankCardBind({ payload, callback }, { put, call }) {
      const res = yield call(bank_CardBind, { ...payload });
      console.log(res,23);
          if (res.success) {
              if (callback && typeof callback === 'function') {
                  callback(res);
              }
          }
    },
    * card_bank_Finish({ payload, callback }, { put, call }) {
      const res = yield call(bank_Finish, { ...payload });
        console.log(res,23);
          if (res.success) {
              if (callback && typeof callback === 'function') {
                  callback(res);
              }
        }
    },
    * card_bank_SendSms({ payload, callback }, { put, call }) {
      const res = yield call(bank_SendSms, { ...payload });
      console.log(res,23);
      if (res.success) {
              if (callback && typeof callback === 'function') {
                  callback(res);
              }
          }
    },
    * card_bank_ValdateCardNo({ payload, callback }, { put, call }) {
      const res = yield call(bank_ValdateCardNo, { ...payload });
      console.log(res,23);
      if (res.success) {
          if (callback && typeof callback === 'function') {
              callback(res);
          }
          }
    },
    // 51支持的银行卡
    * card_bank_list({ payload, callback }, { put, call }) {
        const res = yield call(bank_Support, { ...payload });
        console.log(res,23);
        if (res.success) {
            if (callback && typeof callback === 'function') {
                callback(res);
            }
        }
    },
  }
};