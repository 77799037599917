
import {request, api} from '../utils/index';
const {bank_get_info, bank_send_msg, bank_sign_in,bank_support, bank_cardBind, bank_finish,bank_sendSms,bank_valdateCardNo} = api;

/*51银行卡签约*/
export function bank_CardBind(data) {

  return request({
    url: bank_cardBind,
    method: 'post',
    data,
  })
}
/*51银行卡签约完成*/
export function bank_Finish(data) {

  return request({
    url: bank_finish,
    method: 'post',
    data,
  })
}
/*51签约发送验证码*/
export function bank_SendSms(data) {

  return request({
    url: bank_sendSms,
    method: 'post',
    data,
  })
}

/*51银行卡签约*/
export function bank_ValdateCardNo(data) {

  return request({
    url: bank_valdateCardNo,
    method: 'get',
    data,
  })
}

/*51支持的银行卡列表*/
export function bank_Support(data) {
  return request({
    url: bank_support,
    method: 'get',
    data,
  })
}






/*借款记录*/
export function bank_info(data) {
  return request({
    url: bank_get_info,
    method: 'get',
    data,
  })
}

/*借款*/
export function bank_msg_get(data) {
  return request({
    url: bank_send_msg,
    method: 'post',
    data,
  })
}

/*借款详情*/
export function bank_sign(data) {

  return request({
    url: bank_sign_in,
    method: 'post',
    data,
  })
}

