import axios from 'axios'
import qs from 'qs';
import Cookies from "js-cookie";
import APP from './APP';
import {BaseUrl} from "./index";
import {desCode} from "./encryption";

const {Env} = require('../config/env');

const isEncryption = Env === 'prod';
// 添加请求拦截器，提交的时候加密
axios.interceptors.request.use(function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    });

// 添加返回拦截，返回的时候解密
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

/*Portal  废弃*/
const FetchPortal = async (options) => {
    let {data, url} = options;
    let rd = Cookies.get("rd");
    if (!rd) {
        if (APP.BROWSER.isclient) {
            rd = await APP.DEVICE();
        } else {
            rd = JSON.stringify({
                "appName": "SJJQ",
                "appSourceId": 1
            })
        }
    }
    if (data.params) {
        rd = JSON.stringify(Object.assign({}, data.params, JSON.parse(rd)));
        delete data.params;
    }
    if (Env === "prod") {
        data.rd = desCode.ncrypted(rd);
    } else {
        data.rd = rd;
    }
    
    
    return axios.post(url, data, {
            transformRequest: [function (data) {
                data = qs.stringify(data);
                return data;
            }],
            withCredentials: true
        }
    );
};

/*Front*/
const FetchFront = async (options) => {
    
    let {method = 'get', data, url} = options;
    const loanNo = window.localStorage.getItem('loanNo');
    // console.log(options,999);
    // rong 360需要
    const clientNo = window.localStorage.getItem('clientNo');
    if (clientNo ) {
        data['clientNo'] = clientNo;
    }

   
    if (loanNo ) {
        data['loanNo'] = loanNo;
    }
    let fetch;
    
    switch (method.toLowerCase()) {
        case 'get':
            fetch = axios.get(url,
                
                {
                    params: data,
                    withCredentials: true
                });
            break;
        case 'post':
            fetch = axios.post(
                url,
                data,
                {
                    withCredentials: true
                });
               
            break;
        default:
            fetch = axios({...options});
            break;
    }
    return fetch;
};

export default async function request(options) {
    let fetchFn;
    /* Portal 判断*/
    if (options.url.indexOf(BaseUrl.Portal) !== -1) {
        if (APP.BROWSER.isclient) {
            const rd = Cookies.get("rd");
            if (!rd) {
                const deviceInfo = await APP.DEVICE();
                Cookies.set('rd', deviceInfo);
            }
        } else {
            const v = JSON.stringify({
                "appName": "SJJQ",
                "appSourceId": 1
            });
            Cookies.set('rd', v);
        }
        fetchFn = FetchPortal;
    } else {
        fetchFn = FetchFront;
    }
    
    
    return fetchFn(options)
    .then((response) => {
        const {config} = response;
        const {url} = config;
        
        let data = response.data;
        if (data.code === '500') {
            window.localStorage.setItem('msg',data.message)
            throw new Error(data.message);
        }
        
        if (url.indexOf(BaseUrl.Portal) !== -1) {
            const {desData} = data;
            if (!data.succ) {
                throw new Error(data.message);
            }
            // 如果是生成环境，就解密
            if (Env === "prod") {
                data.desData = desCode.decrypted(desData);
            }
            
            return Promise.resolve({...data})
            
        } else {
            return Promise.resolve({...data})
        }
        
        
    })
    .catch((error) => {
        const {response} = error;
        let msg;
        let statusCode;
        if (response && response instanceof Object) {
            const {data, statusText} = response;
            statusCode = response.status;
            msg = data.message || statusText;
        } else {
            statusCode = 600;
            msg = error.message || '服务器开小差了...';
            if (error.message === "Network Error") {
                msg = "服务器开小差了"
            }
        }
        
        /* eslint-disable */
        return Promise.reject({succ: false, statusCode, message: msg})
    })
}
