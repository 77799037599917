// const API = 'http://47.99.245.36:8096';
// const API = 'https://front.jqtxxedk.com';
// const API = 'https://middle.jqtianxia.com';

// const API = '/dev';
const {API_Portal,API_Front} = require('./env');
// const API = 'http://121.196.26.20:8096';

export default {
  /*Portal 所有接口*/
  interface_url: `${API_Portal}/app/htmlGateway.do`,
  /**51公积金绑定银行卡**/ 
  // 支持的银行卡列表
  // bank_support:`${API_Front}/cooperative_partner/support_banks`,
  bank_support:`${API_Front}/card/support_banks`,
  // 银行卡签约
  // bank_cardBind:`${API_Front}/cooperative_partner/card_binding`,
  bank_cardBind:`${API_Front}/card/card_binding`,
  // 银行卡签约完成
  // bank_finish:`${API_Front}/cooperative_partner/card_finish`,
  bank_finish:`${API_Front}/card/card_finish`,
  //签约发送验证码
  // bank_sendSms:`${API_Front}/cooperative_partner/card_sms`,
  bank_sendSms:`${API_Front}/card/card_sms`,
  //银行卡签约
  // bank_valdateCardNo:`${API_Front}/cooperative_partner/card_valdateCardNo`,
  bank_valdateCardNo:`${API_Front}/card/card_valdateCardNo`,
  /**人品钱包还款**/
   /*还款*/
   pay_submit:`${API_Front}/hy/h5/back`,
     // 计算还款
    pay_check_settle:`${API_Front}/hy/h5/backFee`,
    /*全部结清数据*/
    init_pay_all: `${API_Front}/hy/h5/backFee`,
    /*还款短信  */
    init_pay_sms:`${API_Front}/hy/h5/backSms`,
    // 添加银行卡 
    init_add_bank:`${API_Front}/hy/h5/cardBind`,
    // 绑定银行发送验证码
    init_send_bank:`${API_Front}/hy/h5/cardSms`,
    /*用户银行卡列表  */
    init_Bank_list: `${API_Front}/hy/h5/cradList`,
    /*花鸭账单详情页初始化数据 */
    init_pay_stage: `${API_Front}/hy/h5/loanDetail`,
}


