import ProTypes from 'prop-types';
import React from 'react';
import './index.less';
import APP from '../../utils/APP.js'
class BankList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      iconAdd: require('./imgs/i-bank-add.png'),
      iconNew: require('./imgs/i-bank-new.png'),
      iconChecked: require('./imgs/i-bank-checked.png'),
      currentBankNum: null
    };
  }

  JumpBank() {
    APP.JUMP("winwallet://page/BindBankActivity");
    this.props.onClick && this.props.onClick();
  }

  ChangeBank(item) {
    const {bankAccount4} = item;
    this.setState({
      currentBankNum: bankAccount4
    })
  }
  btnjump(){
    this.props.history.push({pathname: "/bankAdd",});
  }

  render() {
    const {list, onClick,bankFlag} = this.props;
    const {iconAdd, iconNew, iconChecked, currentBankNum} = this.state;
    
    
      const RenderBankItem = list && list.map((item, index) => {
        const {openBankName, logo, bankAccount4,netLogo} = item;
        return (
          <div key={index} onClick={() => {
            this.ChangeBank(item);
            onClick && onClick(item);
          }} className={'m-bank-item border'}>
            <div className={'m-b-icon'}>
              <img alt={'bankIcon'} src={netLogo}/>
            </div>
            <div className={'m-b-name'}>
              {openBankName}({bankAccount4})
            </div>
            {bankAccount4 === currentBankNum &&
            <div className={'m-b-status'}><img alt={'bankChecked'} src={iconChecked}/></div>}
          </div>
        )
      });
    
    
    let AddBankButton =  ''; 
    //此处用来判断用户是否可以绑卡 !=false 
    if(bankFlag !=false){
      AddBankButton =  <div onClick={() => {
        // window.TDAPP.onEvent("借款页-添加新银行卡-进入添加银行卡页");
        // this.JumpBank()
        this.btnjump()
       
      }} 
     
      className={'m-bank-item border'}>
        <div className={'m-b-icon'}>
          <img alt={'bankIcon'} src={iconAdd}/>
        </div>
        <div className={'m-b-name'}>
          添加新的银行卡
        </div>
        <div className={'m-b-status m-b-status-new'}>
          <img alt={'bankNew'} src={iconNew}/>
        </div>
      </div>

    }

    return (
      <div className={'c-bank-list'}>
        {list && RenderBankItem}

        { AddBankButton }
       
      </div>
    )
  }


}

BankList.propTypes = {
  onClick: ProTypes.func,
  list: ProTypes.array,
  bankFlag:ProTypes.bool,
  currentBankNum: ProTypes.string
};

export default BankList;
